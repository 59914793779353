
.video-links {
  display: flex;
  gap: 10px; /* Adjust the gap between links as needed */
}

#video-popup-container {
  display:none;
  position: fixed;
  z-index: 996;
  width: 70%;
  left: 15%;
  /* margin-left: -25%; */
  top: 9vh;
  background-color: #fff;
  /* border-radius: 25px; */
}

#video-popup-close {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 998;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  font-size: 12px;
  background-color: #000;
  line-height: 25px;
  color: #fff;
}

#video-popup-video-container {
  position: absolute;
  z-index: 997;
  width: 100%;
  padding-bottom: 56.25%;
  border: 1px solid #000;
  border-radius: 25px;
  /* background-color: #000; */
}

#video-popup-video {
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  border-radius: 25px;
  object-fit: cover;
}

#video-popup-overlay {
  display: none;
  position: fixed;
  z-index: 995;
  top: 0;
  background-color: #000;
  opacity: 0.8;
  width: 100%;
  height: 100%;
}

#video-popup-close:hover {
  color: #c87e4f;
}

/* Add this class to blur the background */
.blur-background > *:not(#video-popup-container) > *:not(#video-popup-video) {
    filter: blur(3px);
    transition: 0.175s;
}

body * {
    transition: filter 0.175s;
}


@media only screen and (max-width: 767px) {
  /* Adjust video popup container for mobile */
  #video-popup-container {
    width: 90%; /* Adjust as needed */
    left: 5%;
    /* top: 2rem;   */

    margin-left: 0;
  }
  
  /* Adjust video popup video container for mobile */
  #video-popup-video-container {
    padding-bottom: 177.78%; /* 16:9 aspect ratio for horizontal videos */
  }
}

@media only screen and (min-width: 768px) {
  /* Adjust video popup video container for larger screens */
  #video-popup-video-container {
    padding-bottom: 56.25%; /* 16:9 aspect ratio for horizontal videos */
  }
}

